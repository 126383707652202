<template>
  <el-dialog
    append-to-body
    width="800px"
    title="查看"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="tableWrap">
      <el-divider content-position="left">基本信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">流水号</td>
            <td>{{ detailInfo.order_no }}</td>
            <td colspan="4" class="des" style="width:120px;">代理姓名</td>
            <td>{{ detailInfo.name }}</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">手机号</td>
            <td>{{ detailInfo.phone }}</td>
            <td colspan="4" class="des" style="width:120px;">提现金额(元)</td>
            <td>{{ detailInfo.amount }}元</td>
          </tr>
          <tr>
            <td class="des" style="width:120px;">申请时间</td>
            <td>{{ detailInfo.create_time}}</td>
            <template v-if="detailInfo.status === 2">
              <td colspan="4" class="des" style="width:120px;">通过时间</td>
              <td>{{detailInfo.pass_time}}</td>
            </template>
            <template v-if="detailInfo.status === 3">
              <td colspan="4" class="des" style="width:120px;">拒绝时间</td>
              <td>{{detailInfo.refuse_time}}</td>
            </template>
          </tr>
        </tbody>
      </table>
      <el-divider content-position="left">账户信息</el-divider>
      <table cellspacing="0" cellpadding="0" border="0" class="el-table__body">
        <tbody>
          <tr>
            <td class="des" style="width:120px;">账户名</td>
            <td>{{ detailInfo.name }}</td>
            <td colspan="4" class="des" style="width:120px;">支付账号</td>
            <td>{{ detailInfo.ali_account }}</td>
          </tr>
        </tbody>
      </table>
      <template v-if="detailInfo.status === 3">
        <el-divider content-position="left">拒绝理由</el-divider>
        <el-input
          :readonly="true"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4}"
          v-model="detailInfo.reason">
        </el-input>
      </template>
    </diV> 
  </el-dialog>
</template>
<script>
  export default {
    name: 'FinanceDetail',
    data() {
      return {
        visible: false,
        detailInfo: {},
      }
    },
    created() {
      this.getDetail();
    },
    methods: {
      getDetail(row) {
        console.log(row);
        if(!!row) {
          this.$http.get('/admin/withdraw/info', {params: {id: row.id } }).then(res => {
            if(res.code === 1) {
              this.detailInfo = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      },
      reset(done) {
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
     
    }
  }
</script>
<style scoped lang="scss">
// 详情自定义table表格样式
.tableWrap .list {
  padding: 0 12px;
  margin-bottom: 30px;
}
 .tableWrap table {
  width: 100%;
  border-collapse: collapse;
}
 .tableWrap table td{
  height: 30px;
  line-height: 22px;
  padding: 5px;
  border: 1px solid #ddd;
}
.tableWrap .des {
  background: #fbfbfb;
}
.tableWrap .d-title {
  height: 80px;
  line-height: 80px;
}

</style>
