<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.status" @change="handleTabs">
          <el-radio-button :label="4">入账明细</el-radio-button>
          <el-radio-button :label="1">审核中</el-radio-button>
          <el-radio-button :label="2">提现成功</el-radio-button>
          <el-radio-button :label="3">提现未通过</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="" prop="keyword">
            <el-input clearable v-model="table.params.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <!-- 入账明细 -->
      <VTable
        v-if="this.table.params.status === 4"
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <!-- <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-view" @click="showDetail(row)">查看</el-button> 
        </template> -->
      </VTable>
      <!-- 审核中 -->
      <VTable
        v-if="this.table.params.status === 1"
        has-pagionation
        title=''
        addText='添加'
        :field="field1"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-circle-check" @click="showAccept(row)">确认通过</el-button> 
          <el-button type="text" size="mini" icon="el-icon-circle-close" class="delBtn" @click="showRefuse(row)">拒绝</el-button> 
        </template>
      </VTable>
      <!-- 提现成功 -->
      <VTable
        v-if="this.table.params.status === 2"
        has-pagionation
        title=''
        addText='添加'
        :field="field2"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" icon="el-icon-view" @click="showDetail(row)">查看</el-button> 
        </template>
      </VTable>
      <!-- 提现未通过 -->
      <VTable
        v-if="this.table.params.status === 3"
        has-pagionation
        title=''
        addText='添加'
        :field="field3"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:action="{row}">
          <template v-if="table.params.status ===2 || table.params.status === 3">
            <el-button type="text" size="mini" icon="el-icon-view" @click="showDetail(row)">查看</el-button> 
          </template>
        </template>
      </VTable> 
    </div>
    <detail ref="detail"></detail>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import Detail from './components/Detail.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'ReportList',
  mixins:[mixinTable],
  components: {
    VTable,
    Detail,
  },
  data() {
    return {
      field: [
        { name: "company_name", label: "充值企业", hidden: false },
        { name: "amount", label: "充值金额(元)", hidden: false },
        { name: "order_no", label: "充值流水", hidden: false },
        { name: "create_time", label: "充值时间", hidden: false },
      ],
      field1: [
        { name: "name", label: "代理姓名", hidden: false },
        { name: "phone", label: "手机号", hidden: false },
        { name: "ali_account", label: "支付宝账号", hidden: false },
        { name: "amount", label: "提现金额(元)", hidden: false },
        { name: "create_time", label: "申请时间", hidden: false },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      field2: [
        { name: "order_no", label: "流水号", hidden: false },
        { name: "name", label: "代理姓名", hidden: false },
        { name: "phone", label: "手机号", hidden: false },
        { name: "ali_account", label: "支付宝账号", hidden: false },
        { name: "amount", label: "已提现金额(元)", hidden: false },
        { name: "pass_time", label: "通过时间", hidden: false },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      field3: [
        { name: "order_no", label: "流水号", width:"200", hidden: false },
        { name: "name", label: "代理姓名", hidden: false },
        { name: "phone", label: "手机号", hidden: false },
        { name: "ali_account", label: "支付宝账号", width:"200", hidden: false },
        { name: "amount", label: "提现金额(元)", hidden: false },
        { name: "pass_time", label: "通过时间", hidden: false },
        { name: "action", label: "操作", width: "160", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          keyword: '',
          status: 4, // 状态 4:入账明细 1:审核中 2:已通过 3:已拒绝
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/withdraw/list',  {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    //通过 状态 2:通过
    showAccept(row) {
      this.$confirm('请确认操作？','提示',{
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then(() => {
        let _params = {
          id: row.id,
          status: 2,
        }
        this.$http.post('/admin/withdraw/operation', _params).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable()
          } else {
            this.$message.success(res.msg)
          }
        })
      }).catch(() => {})
    },
    // 拒绝 状态  3:拒绝
    showRefuse(row) {
      this.$prompt('请输入拒绝理由（100字之内）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        let _params = {
          id: row.id,
          status: 3,
          reason: value,
        }
        this.$http.post('/admin/withdraw/operation', _params).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          } else {
            this.$message.error(res.msg)
          }
        })
      }).catch(() => {});
    }
    
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>